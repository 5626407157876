import { useTopBarMenu } from '@/lib/hooks/menu';
import { NavLink, NavListContainer, StyledLink } from './NavList.styles';
import { useRouter } from 'next/router';
import { Search } from '@mui/icons-material';
import { useSessionStore } from '@bamboo/core-lib/repo/session';

export const NavList = () => {
  const router = useRouter();
  const [menu] = useTopBarMenu();
  const { jwt } = useSessionStore();

  return (
    <NavListContainer>
      {menu.map((page, i) => (
        <NavLink
          href={page.to}
          key={page.to + i}
          selected={router.pathname === page.to}
        >
          {/* {page.title === 'Pesquisar' && !isLoggedIn ? <Search /> : null} */}
          {page.to === '/plans' ? <strong>{page.title}</strong> : page.title}
        </NavLink>
      ))}
    </NavListContainer>
  );
};
