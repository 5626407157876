'use client';

import { useRouter } from 'next/router';
import { NavList } from './components/nav-list/NavList';
import { ActionContainer } from './components/nav-list/NavList.styles';
import { LogInButton } from './components/log-in-button/LogInButton';
import SideNavigation from '../TobarSideNavigation';
import {
  InputContainer,
  NavHeaderContainer,
  SideNavigationContainer,
  StyledInput,
  StyledButton,
  StyledLogo,
} from './NavHeader.styles';
import { MenuRounded, Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSessionStore } from '@bamboo/core-lib/repo/session';
import { useMediaQuery } from '@/lib/hooks/useMediaQuery';

export const NavHeader = () => {
  const [search, setSearch] = useState('');
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();
  const { jwt } = useSessionStore();
  const isMobileScreen = useMediaQuery('(max-width:1200px)');

  useEffect(() => setLoaded(true), []);

  const handleSearch = () => {
    if (!search) return;

    router.push(`/search?search_text=${search}`);
  };

  const isHomePage = router.pathname === '/' || router.pathname === '/home';
  const isLoggedIn = jwt;

  return (
    <NavHeaderContainer>
      <StyledLogo onClick={() => router.push('/')} />
      {loaded ? (
        isMobileScreen ? (
          // When it's mobile screen
          <SideNavigationContainer>
            <div>
              <StyledButton onClick={() => router.push('/plans')}>
                Planos
              </StyledButton>
            </div>
            <SideNavigation
              icon={<MenuRounded style={{ height: '36px', width: '36px' }} />}
            />
          </SideNavigationContainer>
        ) : (
          // When it's desktop screen
          <>
            <NavList />
            <ActionContainer>
              {!isHomePage && (
                <InputContainer>
                  <StyledInput
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Pesquisar..."
                  />
                  <Search
                    style={{
                      color: '#969696',
                      fontSize: '20px',
                    }}
                    onClick={handleSearch}
                  />
                </InputContainer>
              )}
              <LogInButton />
            </ActionContainer>
          </>
        )
      ) : null}
    </NavHeaderContainer>
  );
};
